<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增记录"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="客户名称">
          <a-select
            v-decorator="['agent_id', {
              rules: [
                { required: true, message: '客户名称' },
              ]
            }]"
            :show-arrow="false"
            show-search
            allow-clear
            :filter-option="false"
            :not-found-content="loadingAgentOptions ? undefined : null"
            label-in-value
            placeholder="输入客户名称加载选项"
            @search="fetchAgentOptions"
            :loading="loadingAgentOptions"
          >
            <a-spin v-if="loadingAgentOptions" slot="notFoundContent" size="small" />
            <a-select-option
              v-for="option in agentOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="标题">
          <a-input
            v-decorator="['title', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入标题' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="`类型`">
          <a-select
            placeholder="请选择类型"
            v-decorator="['type', {
              rules: [
                { required: true, message: '类型' },
              ]
            }]"
            allow-clear
          >
            <a-select-option
              v-for="option in issueTypeOptions"
              :key="option.slug"
              :value="option.slug"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="日期">
          <a-date-picker
            style="width: 100%"
            v-decorator="[
              'happen_date',
              {
                rules: [{ required: true, message: '请选择发生日期' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="描述">
          <wang-editor
            v-decorator="['description', {
              rules: [
                { max: 65535, message: '最多65535个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findAgentOptions } from '@/api/agent'
import { formatDate } from '@/utils/time'
import { findIssueTypeOptions, createCustomerServiceIssues } from '@/api/customer_service_manage'

import WangEditor from '@/components/Editor/WangEditor'

export default {
  name: 'NewIssue',
  components: {
    WangEditor
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'issue' }),
      submitting: false,
      issueTypeOptions: [],
      agentOptions: [],
      loadingIssueTypeOption: true,
      loadingAgentOptions: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchIssueTypeOptions()
  },
  methods: {
    // 加载所属用户选项数据
    fetchAgentOptions(name) {
      if (!this.$lodash.trim(name)) {
        return
      }

      this.loadingAgentOptions = true
      findAgentOptions({ name: name }).then(res => {
        if (res.code === 0) {
          this.agentOptions = res.data
        }
        this.loadingAgentOptions = false
      })
    },

    // 加载问题类型选项
    fetchIssueTypeOptions() {
      this.loadingIssueTypeOption = true
      findIssueTypeOptions().then((res) => {
        if (res.code === 0) {
          this.issueTypeOptions = res.data
        }
        this.loadingIssueTypeOption = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true

          const fieldsValue = this.form.getFieldsValue()
          values.agent_id = fieldsValue.agent_id && fieldsValue.agent_id.key
          values.happen_date = formatDate(values.happen_date)

          createCustomerServiceIssues(values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
